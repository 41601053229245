<template>
  <div class="attendeeMonitor layout-warp">
    <div class="item-info">
      <div class="img">
        <img src="./../../../../../assets/images/attendeeMonitor.png" alt="" />
      </div>
      <div class="info-text">
        <p>
          <span class="allCount">参会者人数：{{ sum }}人</span>
          <span class="online">当前在线人数：{{ Online }} 人</span>
          <span class="outline">当前离线人数：{{ Offline }} 人</span>
        </p>
      </div>
    </div>

    <!-- <div class="formConfigList">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="参会者类型">
          <el-select v-model="formInline.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参会者角色">
          <el-select v-model="formInline.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参会者状态">
          <el-select v-model="formInline.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参会者名称">
          <el-input v-model="formInline.user" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fun_Submit">查询</el-button>
        </el-form-item>
      </el-form>
    </div> -->

    <div class="video-list">
      <div class="item" v-for="(item, key) in videoList" :key="key">
        <div class="video-box">
          <!-- src="https://vd3.bdstatic.com/mda-mansimvpjrgtxnr3/sc/cae_h264_clips/1611399414/mda-mansimvpjrgtxnr3.mp4?auth_key=1611889684-0-0-5b83ec62109c1f84a8e57506ae39d34e&bcevod_channel=searchbox_feed&pd=1&pt=3&abtest=8_2" -->
          <video :src="item.fileUrl" controls="controls"></video>
        </div>
        <div class="info-wrap">
          <div class="user-img-box">
            <img
              class="user-img"
              src="./../../../../../assets/images/video_user_img.png"
            />
          </div>
          <div class="text-box">
            <h3>招标人: {{ item.userRealName }}</h3>
            <ul class="type-list">
              <li>
                会议类型：<span>{{ item.meetingType }}</span>
              </li>
              <!-- <li>参会者角色：<span>招标人/采购人</span></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page-number">
      <el-pagination
        class="layout-pagein"
        @size-change="fun_LimitChange"
        @current-change="fun_PageChange"
        :current-page="reqPageData.current"
        :page-sizes="[4, 10, 50, 100]"
        :page-size="reqPageData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="reqPageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.attendeeMonitor {
  .item-info {
    display: flex;
    .img {
    }
    .info-text {
      line-height: 38px;
      padding-left: 20px;
      font-size: 16px;
      float: left;
      padding-top: 12px;
      span {
        display: inline-block;
        width: 150px;
        margin-right: 10px;
      }
      .allCount {
        color: #249980;
      }
      .online {
        color: #32cd32;
      }
      .outline {
        color: #7fafdd;
      }
    }
  }
  .formConfigList {
    display: inline-block;
  }
  .video-list {
    .item {
      float: left;
      width: 48%;
      min-width: 250px;
      max-width: 48%;
      padding: 1%;
      box-sizing: border-box;
      .video-box {
        font-size: 0;
        video {
          display: block;
          width: 100%;
          line-height: 0px;
        }
      }

      .info-wrap {
        background: #0f3559;
        display: flex;
        box-sizing: border-box;
        padding: 10px;
        .user-img-box {
          .user-img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .text-box {
          padding-left: 10px;
          h3 {
            font-size: 16px;
            font-weight: bold;
            line-height: 46px;
            color: #fff;
          }
          .type-list {
            li {
              float: left;
              color: #fff;
              padding-right: 50px;
            }
          }
        }
      }
    }
  }
  .video-list::after {
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
  .page-number {
    .el-pagination {
      text-align: right;
    }
  }
}
</style>

<script>
// 引入api
import { fun_PostAction } from "@/api";
import { fun_MeetingType } from "@/utils/validate.js";
export default {
  data: () => {
    return {
      sum:0,              // 总人数
      Online:0,           // 在线人数
      Offline:0,          // 离线人数
      videoList: [],
      formInline: {
        user: "",
        region: "",
      },
      // 详情表单基础数据
      formConfigList: [
        {
          label: "字典名称：",
          value: "username",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入用户名称",
            trigger: "blur",
          },
        },
        {
          label: "字典编码：",
          value: "userpassword",
          type: "textarea",
          required: true,
          rule: {
            required: true,
            message: "请输入字典编码",
            trigger: "blur",
          },
        },
        {
          label: "字典描述：",
          value: "zsmc",
          type: "textarea",
          required: true,
          rule: {
            required: true,
            message: "请输入字典描述",
            trigger: "blur",
          },
        },
      ],
      // 后台返回错误对象
      errRules: {},
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 4, // 每页条数
        total: 0, // 总条数
      },
    };
  },
  mounted() {
    this.fun_Detail();
    // 获取一览表格数据
    this.fun_GetList();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    /*
     * 列表
     */
    async fun_GetList() {
      let res = await fun_PostAction(
        "/projectInfo/meetingInfoForMonitoringlist",
        {
          id: this.id,
          ...this.reqPageData,
        }
      );
      const { result, data, msg } = res.data;
      if (result) {
        // 解构data
        const { total, records } = data;
       records.map((e) => {
         e.meetingType = fun_MeetingType(e.meetingType);
       }) 
        // 列表赋值
        this.videoList = records;
        // 获取分页总条数
        this.reqPageData.total = total;
      } else {
        // 提示请求失败消息
        this.$message.error(msg);
      }
    },
    /* 
    * 详情
    */
   async fun_Detail(){
     // 请求
     let res = await fun_PostAction("/projectInfo/powerNumber");
     // 解构
     const { result , data , msg } = res.data;
     // 判断请求结果
     if(result){
       // 赋值各人数
       this.sum = data.sum;
       this.Online = data.Online;
       this.Offline = data.Offline;
     }
   },

    fun_Submit() {},
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */

    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },

  },
};
</script>