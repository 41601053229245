<template>
  <div class="logManage layout-warp">
<!--     <div class="info">
      <h3 class="projectName">测试项目002</h3>
      <p class="list-text">
        <span>参会者：【见证人员_监督/见证人员】</span>
        <span>会议时间：2020-08-17 16:42:01</span>
        <span>会议类型：开标会</span>
        <span>会议状态：进行中</span>
        <span>项目编号：202008100002</span>
      </p>
    </div> -->
    <div class="content-tab-wrap">
      <el-tabs type="card">
        <el-tab-pane label="操作记录">
          <OperatingRecord></OperatingRecord>
        </el-tab-pane>
        <el-tab-pane label="运行监控"> 
          <RunMonitor></RunMonitor>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.logManage {
  .info {
    .projectName {
      font-weight: bold;
      font-size: 20px;
      color: #000;
      line-height: 28px;
      text-align: center;
    }
    .list-text {
      line-height: 42px;
      color: #333;
      span {
        padding-right: 60px;
      }
    }
  }
  .content-tab-wrap{
    padding-top: 20px;
    /deep/.el-tabs__header{
      margin-bottom: 0px;
    }
  }
}
</style>
<script>
import OperatingRecord from "./operatingRecord/index.vue";
import RunMonitor from "./runMonitor/index.vue";
export default {
  data: () => {
    return {};
  },
  components: {
    OperatingRecord,
    RunMonitor,
  },
};
</script>