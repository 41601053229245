// 引入api
import { fun_PostAction } from "@/api";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      activeNames: ["1", "2"],
      // 查询表单字段
      detailInfo: {

      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "参会者类型：",
          value: "userRole",
          placeholder: "请选择"
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          type: "input",
          label: "参会者角色：",
          value: "userRole",
          placeholder: "请选择"
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "批量下载",
          type: "primary",
          icon: "document-add"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "会议室类型",
          prop: "hyslx"
        },
        {
          label: "会议名称",
          prop: "hymc"
        },
        {
          label: "参会者姓名",
          prop: "chzxm"
        },
        {
          label: "参会者类型",
          prop: "chzlx"
        },
        {
          label: "参会者角色",
          prop: "chzjs"
        },
        {
          label: "上传时间",
          prop: "scsj"
        },
        {
          label: "操作",
          slotName: "cz"
        }
      ],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        page: 1, // 页码
        limit: 10, // 每页条数
        total: 10 // 总条数
      },
      multipleSelection: []
    };
  },
  props: {
    DetailId: ""
  },
  // 模板编译完成
  mounted() {
    this.fun_GetDetail();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    async fun_GetDetail() {
      // 请求接口
      // let res = await fun_PostAction("meetingUserInfo/videoDetail", {
      let res = await fun_PostAction("monitoringRecord/videoDetail", {
        id: this.DetailId
      });
      // 解构
      const { result, data, msg } = res.data;
      // 判断返回
      if (result) {
        // 解构
        this.detailInfo = data;
        console.log(this.detailInfo);
      } else {
        // 请求失败，提示信息
        this.$message.error(msg);
      }

    },

    /* 
    * 返回
    */
    fun_Back() {
      this.$parent.fun_Back();
    }

  }
};
