// 引入api
import { fun_PostAction } from "@/api";
// 导入组件
import AttendeeMonitor from "./../components/attendeeMonitor/attendeeMonitor"; //会议监控
import LogManage from "./../components/logManage/logManage"; //日志管理
import VideoCheck from "./../components/videoCheck/videoCheck"; //视频查看
import VideoDetail from "./../components/videoCheck/details/index.vue"; //视频详情

export default {
  data: () => {
    return {
      DetailId: null,
      activeName: "1",
      info: {},
      form: {
        id: ""
      },
      IsShow: false
    };
  },
  components: {
    AttendeeMonitor,
    LogManage,
    VideoCheck,
    VideoDetail
  },
  computed: {
    // 获取历史标签name
    proManActive() {
      // 返回历史标签name
      return this.$store.state.proManActive;
    }
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.fun_Detail();
    // 如果是从详情页点击返回到一览时
    if (this.$route.query.tab) {
      // 显示历史tab页
      this.activeName = this.proManActive;
    } else {
      // 如果是点击侧边栏进入一览时
      // 向mutations提交SET_ACTIVE方法,并将参数name设置为1
      this.$store.commit("SET_ACTIVE", "1");
      // 默认显示第一个tab页
      this.activeName = "1";
    }
  },
  methods: {
    /* 
    * 详情
    */
    async fun_Detail() {
      // 打开loading
      this.loading = true;
      // 请求，获取结果集
      let res = await fun_PostAction("/projectInfo/powerDetail", {
        ...this.form
      });
      // 解构res.data
      const { result, data, msg } = res.data;
      if( result ){
        this.info = data;
      }
    },
    /**
     * @description 点击tabs标签触发
     * @param {e} 点击获取的标签元素
     * @author 滕威
     */
    fun_TabClick(e) {
      // 向mutations提交SET_ACTIVE方法,并携带参数name
      this.$store.commit("SET_ACTIVE", e.name);
    },
    fun_LookDetails(e){
      this.IsShow = true;
      this.DetailId = e.data.id;
    },
    fun_Back(){
      this.IsShow = !this.IsShow;
    }
  }
};
